<template>
  <div style="text-align: justify" class="pa-sm-8 pa-md-14">
    <v-container>
      <legal-header></legal-header>

      <p>
        <strong
          >&Pi;&Omicron;&Lambda;&Iota;&Tau;&Iota;&Kappa;&Eta;
          &Pi;&Lambda;&Eta;&Rho;&Omega;&Mu;&Omega;&Nu;</strong
        >
      </p>
      <p><br /></p>
      <p>
        <strong
          >&Tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
          &Epsilon;&nu;&eta;&mu;έ&rho;&omega;&sigma;&eta;: 01/10/2020</strong
        >
      </p>
      <p><br /></p>
      <p><br /></p>
      <p>
        &Eta; &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;
        &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
        &Pi;&lambda;&eta;&rho;&omega;&mu;ώ&nu;
        &alpha;&pi;&omicron;&tau;&epsilon;&lambda;&epsilon;ί
        &alpha;&nu;&alpha;&pi;ό&sigma;&pi;&alpha;&sigma;&tau;&omicron;
        &mu;έ&rho;&omicron;&sigmaf; &tau;&omega;&nu; Ό&rho;&omega;&nu;
        &Chi;&rho;ή&sigma;&eta;&sigmaf; &tau;&eta;&sigmaf; Stoferno.gr,
        &tau;&omicron;&upsilon;&sigmaf;
        &omicron;&pi;&omicron;ί&omicron;&upsilon;&sigmaf;
        &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
        &beta;&rho;&epsilon;ί&tau;&epsilon; &epsilon;&delta;ώ
        &kappa;&alpha;&iota; &tau;&omicron;&upsilon;&sigmaf;
        &omicron;&pi;&omicron;ί&omicron;&upsilon;&sigmaf;
        έ&chi;&epsilon;&tau;&epsilon;
        &alpha;&pi;&omicron;&delta;&epsilon;&chi;&theta;&epsilon;ί
        &kappa;&alpha;&tau;ά &tau;&eta;&nu;
        &epsilon;&gamma;&gamma;&rho;&alpha;&phi;ή &sigma;&alpha;&sigmaf;
        &sigma;&tau;&omicron; Stoferno.gr
      </p>
      <p>
        &Alpha;&nu;&alpha;&gamma;&nu;&omega;&rho;ί&zeta;&epsilon;&tau;&epsilon;
        ό&tau;&iota; &eta; &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
        έ&chi;&epsilon;&iota; &tau;&eta;&nu;
        &delta;&iota;&alpha;&kappa;&rho;&iota;&tau;&iota;&kappa;ή
        &epsilon;&upsilon;&chi;έ&rho;&epsilon;&iota;&alpha; &kappa;&alpha;&iota;
        &delta;ύ&nu;&alpha;&tau;&alpha;&iota; &nu;&alpha;
        &tau;&rho;&omicron;&pi;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&iota;
        &mu;έ&rho;&omicron;&sigmaf; ή &tau;&omicron;
        &sigma;ύ&nu;&omicron;&lambda;&omicron; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&omicron;ύ&sigma;&alpha;&sigmaf;
        &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή&sigmaf;
        &Pi;&lambda;&eta;&rho;&omega;&mu;ώ&nu;. &Sigma;&epsilon;
        &kappa;ά&theta;&epsilon;
        &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;, &eta;
        &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &theta;&alpha;
        &delta;&eta;&mu;&omicron;&sigma;&iota;&epsilon;ύ&epsilon;&iota;
        &tau;&iota;&sigmaf; &alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
        &sigma;&tau;&eta;&nu;
        &Iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
        &kappa;&alpha;&iota; &theta;&alpha;
        &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&nu;&epsilon;&iota;
        &gamma;&iota;&alpha; &tau;&eta;&nu;
        &tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
        &eta;&mu;&epsilon;&rho;&omicron;&mu;&eta;&nu;ί&alpha;
        &alpha;&lambda;&lambda;&alpha;&gamma;ή&sigmaf; &sigma;&tau;&eta;&nu;
        &alpha;&rho;&chi;ή &tau;&omicron;&upsilon;
        &epsilon;&gamma;&gamma;&rho;ά&phi;&omicron;&upsilon;
        &alpha;&upsilon;&tau;&omicron;ύ, &omicron;&iota; &delta;&epsilon;
        &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
        &alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf; &theta;&alpha;
        &iota;&sigma;&chi;ύ&omicron;&upsilon;&nu; &alpha;&pi;ό &tau;&eta;&nu;
        &eta;&mu;&epsilon;&rho;&omicron;&mu;&eta;&nu;ί&alpha;
        &alpha;&upsilon;&tau;ή.
      </p>
      <p>
        <strong
          >1. &Chi;&Rho;&Epsilon;&Omega;&Sigma;&Epsilon;&Iota;&Sigma;</strong
        >
      </p>
      <p>
        &Omicron;&iota; &chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf;
        &tau;&eta;&sigmaf;
        &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;&sigmaf;
        &chi;&omega;&rho;ί&zeta;&omicron;&nu;&tau;&alpha;&iota; &sigma;&epsilon;
        3 &kappa;&alpha;&tau;&eta;&gamma;&omicron;&rho;ί&epsilon;&sigmaf;:
      </p>
      <p>
        <strong
          >1.1
          &Sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
          Marketplace</strong
        >
      </p>
      <p>
        &Pi;&rho;ό&kappa;&epsilon;&iota;&tau;&alpha;&iota; &gamma;&iota;&alpha;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&epsilon;&sigmaf;
        &kappa;&alpha;&iota;
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
        &pi;&omicron;&upsilon;
        &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;&omicron;ύ&nu;&tau;&alpha;&iota;
        &alpha;&pi;ό &tau;&alpha;
        &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&alpha;
        &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;
        &tau;&eta;&sigmaf;
        &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;&sigmaf;, &omicron;&iota;
        &sigma;&upsilon;&nu;&omicron;&lambda;&iota;&kappa;έ&sigmaf;
        &chi;&rho;&epsilon;ώ&sigma;&epsilon;&iota;&sigmaf; &tau;&omega;&nu;
        &omicron;&pi;&omicron;ί&omega;&nu;
        &epsilon;&mu;&phi;&alpha;&nu;ί&zeta;&omicron;&nu;&tau;&alpha;&iota;
        &pi;ά&nu;&tau;&alpha; &sigma;&tau;&omicron;
        &tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&omicron;
        &beta;ή&mu;&alpha;/&sigma;&tau;&eta;&nu;
        &tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
        &sigma;&epsilon;&lambda;ί&delta;&alpha; (&ldquo;Checkout Page&rdquo;)
        &pi;&rho;&iota;&nu; &tau;&eta;&nu;
        &omicron;&rho;&iota;&sigma;&tau;&iota;&kappa;ή
        &kappa;&alpha;&tau;&alpha;&chi;ώ&rho;&eta;&sigma;&eta;
        &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;.
      </p>
      <p>
        &Gamma;&iota;&alpha; &tau;&eta;&nu; &epsilon;&kappa;
        &mu;έ&rho;&omicron;&upsilon;&sigmaf; &tau;&omicron;&upsilon; Stoferno.gr
        &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta; &tau;&eta;&sigmaf;
        &alpha;&nu;&alpha;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&eta;&sigmaf;
        &epsilon;&nu;&tau;&omicron;&lambda;ή&sigmaf; &sigma;&alpha;&sigmaf;,
        &omicron;&phi;&epsilon;ί&lambda;&epsilon;&tau;&epsilon; &nu;&alpha;
        &kappa;&alpha;&tau;&alpha;&beta;ά&lambda;&epsilon;&tau;&epsilon;
        &sigma;&tau;&eta;&nu; &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
        &tau;&omicron; &alpha;&nu;&tau;ί&tau;&iota;&mu;&omicron;
        &pi;&omicron;&upsilon; έ&chi;&epsilon;&tau;&epsilon; &epsilon;&kappa;
        &tau;&omega;&nu; &pi;&rho;&omicron;&tau;έ&rho;&omega;&nu;
        &alpha;&pi;&omicron;&delta;&epsilon;&chi;&theta;&epsilon;ί
        (&laquo;&kappa;ό&sigma;&tau;&omicron;&sigmaf;
        &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&eta;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;&raquo;),
        &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&eta;&nu;
        &alpha;&nu;&alpha;&lambda;&upsilon;&tau;&iota;&kappa;ά
        &pi;&epsilon;&rho;&iota;&gamma;&rho;&alpha;&phi;ό&mu;&epsilon;&nu;&eta;
        &sigma;&tau;&omicron;&nu; &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
        stoferno.gr
        &tau;&iota;&mu;&omicron;&lambda;&omicron;&gamma;&iota;&alpha;&kappa;ή
        &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή &tau;&eta;&sigmaf;
        &epsilon;&tau;&alpha;&iota;&rho;ί&alpha;&sigmaf;.
      </p>
      <p>
        &Eta; &pi;&lambda;&eta;&rho;&omega;&mu;ή &theta;&alpha;
        &epsilon;&kappa;&tau;&epsilon;&lambda;&epsilon;ί&tau;&alpha;&iota;
        &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
        &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή&sigmaf;
        &phi;ό&rho;&mu;&alpha;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &nbsp;&pi;&omicron;&upsilon; &pi;&alpha;&rho;έ&chi;&epsilon;&iota; &eta;
        Stoferno.gr, &alpha;&phi;&omicron;ύ
        &pi;&rho;&omicron;&eta;&gamma;&omicron;&upsilon;&mu;έ&nu;&omega;&sigmaf;
        &omicron; &chi;&rho;ή&sigma;&eta;&sigmaf;
        &epsilon;&pi;&iota;&beta;&epsilon;&beta;&alpha;&iota;ώ&sigma;&epsilon;&iota;
        &tau;&omicron;
        &pi;&epsilon;&rho;&iota;&epsilon;&chi;ό&mu;&epsilon;&nu;&omicron;,
        &kappa;&alpha;&iota; &tau;&omicron;
        &kappa;ό&sigma;&tau;&omicron;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &tau;&omicron;&upsilon;, &kappa;&alpha;&iota;
        &sigma;&upsilon;&nu;&alpha;&iota;&nu;έ&sigma;&epsilon;&iota;
        &sigma;&tau;&eta;&nu; &pi;&alpha;&rho;&omicron;&chi;ή &tau;&eta;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
        &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά&sigmaf; &alpha;&pi;ό
        &tau;&eta;&nu; &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;.
      </p>
      <p>
        &Omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
        &kappa;&alpha;&tau;&alpha;&beta;ά&lambda;&lambda;&epsilon;&iota;
        &tau;&omicron; &alpha;&nu;&omega;&tau;έ&rho;&omega;
        &chi;&rho;&eta;&mu;&alpha;&tau;&iota;&kappa;ό &pi;&omicron;&sigma;ό,
        &kappa;&alpha;&tau;ά &tau;&eta;&nu;
        &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή
        &epsilon;&pi;&iota;&beta;&epsilon;&beta;&alpha;ί&omega;&sigma;&eta;
        &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &tau;&omicron;&upsilon;, &kappa;&alpha;&iota; &tau;&omicron; Stoferno.gr
        &sigma;&upsilon;&lambda;&lambda;έ&gamma;&epsilon;&iota; &tau;&omicron;
        &pi;&omicron;&sigma;ό &alpha;&upsilon;&tau;ό, &epsilon;&kappa;
        &mu;έ&rho;&omicron;&upsilon;&sigmaf; &tau;&omega;&nu;
        &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&tau;ώ&nu;
        &tau;&omicron;&upsilon;, ή&tau;&omicron;&iota; &tau;&omega;&nu;
        &delta;&iota;&alpha;&nu;&omicron;&mu;έ&omega;&nu; &kappa;&alpha;&iota;
        &tau;&omega;&nu;
        &kappa;&alpha;&tau;&alpha;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu;.
      </p>
      <p>
        &Sigma;&epsilon; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
        &pi;&omicron;&upsilon; &eta;
        &alpha;&nu;&alpha;&phi;&epsilon;&rho;ό&mu;&epsilon;&nu;&eta;
        &sigma;&tau;&omicron;&nu; &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
        &chi;&rho;έ&omega;&sigma;&eta; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;,
        &delta;&iota;&alpha;&phi;έ&rho;&epsilon;&iota; &alpha;&pi;ό
        &tau;&eta;&nu; &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&iota;&kappa;ή,
        &lambda;ό&gamma;&omega; &epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;
        &chi;&rho;&epsilon;ώ&sigma;&epsilon;&omega;&nu; &pi;&omicron;&upsilon;
        &epsilon;&pi;&iota;&beta;ά&lambda;&lambda;&epsilon;&iota; &tau;&omicron;
        &kappa;&alpha;&tau;ά&sigma;&tau;&eta;&mu;&alpha; (ό&pi;&omega;&sigmaf;
        &epsilon;&nu;&delta;&epsilon;&iota;&kappa;&tau;&iota;&kappa;ά
        &alpha;&nu;&alpha;&phi;έ&rho;&epsilon;&tau;&alpha;&iota; &eta;
        &epsilon;&pi;&iota;&beta;&omicron;&lambda;ή
        &pi;&rho;ό&sigma;&theta;&epsilon;&tau;&eta;&sigmaf;
        &chi;&rho;έ&omega;&sigma;&eta;&sigmaf; &lambda;ό&gamma;&omega;
        &chi;&rho;ή&sigma;&eta;&sigmaf;
        &pi;&lambda;&alpha;&sigma;&tau;&iota;&kappa;ή&sigmaf;
        &sigma;&alpha;&kappa;&omicron;ύ&lambda;&alpha;&sigmaf; ή
        &lambda;ό&gamma;&omega; &kappa;ά&pi;&omicron;&iota;&alpha;&sigmaf;
        &pi;&rho;&omicron;&sigma;&theta;ή&kappa;&eta;&sigmaf;
        &epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omicron;&sigmaf; &sigma;&tau;&omicron;
        &kappa;&alpha;&lambda;ά&theta;&iota; &kappa;&alpha;&tau;ά &tau;&eta;
        &delta;&iota;ά&rho;&kappa;&epsilon;&iota;&alpha; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;),
        &omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &theta;&alpha;
        &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&nu;&epsilon;&tau;&alpha;&iota;
        &alpha;&pi;ό &tau;&omicron;
        &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&omicron;
        &kappa;&alpha;&tau;ά&sigma;&tau;&eta;&mu;&alpha; ή/&kappa;&alpha;&iota;
        &tau;&eta;&nu; Stoferno.gr &kappa;&alpha;&iota; &theta;&alpha;
        &sigma;&upsilon;&nu;&alpha;&iota;&nu;&epsilon;ί &rho;&eta;&tau;ώ&sigmaf;
        &sigma;&tau;&eta;&nu; &epsilon;&pi;&iota;&beta;&omicron;&lambda;ή
        &tau;&omega;&nu; &alpha;&nu;&omega;&tau;έ&rho;&omega;
        &chi;&rho;&epsilon;ώ&sigma;&epsilon;&omega;&nu;, &gamma;&iota;&alpha;
        &tau;&iota;&sigmaf; &omicron;&pi;&omicron;ί&epsilon;&sigmaf;
        &omicron;&upsilon;&delta;&epsilon;&mu;ί&alpha;
        &epsilon;&upsilon;&theta;ύ&nu;&eta; &phi;έ&rho;&epsilon;&iota; &eta;
        &epsilon;&tau;&alpha;&iota;&rho;ί&alpha;.
      </p>
      <p>
        &Sigma;&tau;&omicron; &pi;&omicron;&sigma;ό &tau;&eta;&sigmaf;
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ή&sigmaf;
        &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&tau;&alpha;&iota;
        &epsilon;&kappa;&tau;ό&sigmaf; &alpha;&pi;ό &tau;&eta;&nu;
        &alpha;&xi;ί&alpha; &tau;&omicron;&upsilon;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omicron;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &kappa;&alpha;&iota; &tau;&omicron;
        &kappa;ό&sigma;&tau;&omicron;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&eta;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
        &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά&sigmaf; &tau;&omega;&nu;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &tau;&eta;&sigmaf;
        &epsilon;&pi;&iota;&chi;&epsilon;ί&rho;&eta;&sigma;&eta;&sigmaf;
        &sigma;&tau;&omicron;&nu; &tau;&epsilon;&lambda;&iota;&kappa;ό
        &chi;&rho;ή&sigma;&tau;&eta;, &tau;&omicron;
        &omicron;&pi;&omicron;ί&omicron; &epsilon;&phi; &epsilon;&xi;ή&sigmaf;
        &omicron;&rho;ί&zeta;&epsilon;&tau;&alpha;&iota; &omega;&sigmaf;
        &ldquo;delivery fee&rdquo;, ό&pi;&omega;&sigmaf; &alpha;&upsilon;&tau;ό
        &alpha;&nu;&alpha;&gamma;&rho;ά&phi;&epsilon;&tau;&alpha;&iota;
        &sigma;&tau;&eta;&nu;
        &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
        &phi;ό&rho;&mu;&alpha;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;.
        &Tau;&omicron; Stoferno.gr έ&chi;&epsilon;&iota; &tau;&omicron;
        &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; &nu;&alpha;
        &delta;&epsilon;&sigma;&mu;&epsilon;ύ&sigma;&epsilon;&iota;
        &tau;&omicron; &chi;&rho;&eta;&mu;&alpha;&tau;&iota;&kappa;ό
        &pi;&omicron;&sigma;ό &epsilon;&kappa;
        &mu;έ&rho;&omicron;&upsilon;&sigmaf; &tau;&omega;&nu;
        &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&tau;ώ&nu;
        &tau;&eta;&sigmaf; &alpha;&pi;ό &tau;&eta;&nu; &kappa;ά&rho;&tau;&alpha;
        &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;,
        &mu;ό&lambda;&iota;&sigmaf;
        &sigma;&tau;&epsilon;ί&lambda;&epsilon;&iota; &omicron;
        &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &tau;&eta;&nu;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;.
      </p>
      <p>
        &Gamma;&iota;&alpha; &tau;&eta;&nu; &omicron;&rho;&theta;ή
        &pi;&lambda;&eta;&rho;&omega;&mu;ή &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &sigma;&alpha;&sigmaf;
        &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&omega;&nu;
        &tau;&omega;&nu; &epsilon;&iota;&delta;&iota;&kappa;ώ&nu;
        &pi;&epsilon;&rho;&iota;&pi;&tau;ώ&sigma;&epsilon;&omega;&nu;
        &alpha;&nu;&tau;&iota;&kappa;&alpha;&tau;ά&sigma;&tau;&alpha;&sigma;&eta;&sigmaf;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; ή/&kappa;&alpha;&iota;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu;
        &mu;&epsilon;&tau;&alpha;&beta;&lambda;&eta;&tau;&omicron;ύ
        &beta;ά&rho;&omicron;&upsilon;&sigmaf;, &tau;&omicron; Stoferno.gr
        έ&chi;&epsilon;&iota; &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
        &nu;&alpha; &delta;&epsilon;&sigma;&mu;&epsilon;ύ&sigma;&epsilon;&iota;
        15% &epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu; &tau;&eta;&sigmaf;
        &sigma;&upsilon;&nu;&omicron;&lambda;&iota;&kappa;ή&sigmaf;
        &alpha;&xi;ί&alpha;&sigmaf; &tau;&omega;&nu;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &alpha;&pi;ό &tau;&eta;&nu; &kappa;ά&rho;&tau;&alpha;
        &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;.
        &Epsilon;&phi;ό&sigma;&omicron;&nu;
        &pi;&rho;&omicron;&kappa;ύ&psi;&epsilon;&iota; &eta;
        &tau;&epsilon;&lambda;&iota;&kappa;ή &alpha;&xi;ί&alpha;
        &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;,
        &tau;&omicron; &epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;
        &pi;&omicron;&sigma;ό &pi;έ&rho;&alpha;&nu; &tau;&eta;&sigmaf;
        &alpha;&xi;ί&alpha;&sigmaf; &alpha;&upsilon;&tau;ή&sigmaf;
        &theta;&alpha; &epsilon;&pi;&iota;&sigma;&tau;&rho;έ&psi;&epsilon;&iota;
        &sigma;&tau;&eta;&nu; &kappa;ά&rho;&tau;&alpha; &tau;&omicron;&upsilon;
        &chi;&rho;ή&sigma;&tau;&eta;. &Pi;&rho;&omicron;&tau;&omicron;ύ
        &pi;&rho;&alpha;&gamma;&mu;&alpha;&tau;&omicron;&pi;&omicron;&iota;&eta;&theta;&epsilon;ί
        &omicron;&pi;&omicron;&iota;&alpha;&delta;ή&pi;&omicron;&tau;&epsilon;
        &chi;&rho;&eta;&mu;&alpha;&tau;&iota;&kappa;ή
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ή,
        &theta;&alpha;
        &epsilon;&nu;&eta;&mu;&epsilon;&rho;ώ&nu;&epsilon;&sigma;&tau;&epsilon;
        &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ά
        &alpha;&pi;ό &tau;&omicron; &sigma;ύ&sigma;&tau;&eta;&mu;&alpha;
        &tau;&eta;&sigmaf; Stoferno.gr
      </p>
      <p>
        <strong
          >1.2
          &Sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
          Standard Courier -
          &Chi;&iota;&lambda;&iota;&omicron;&mu;&epsilon;&tau;&rho;&iota;&kappa;ή&sigmaf;
          Courier</strong
        >
      </p>
      <p>
        &Pi;&rho;ό&kappa;&epsilon;&iota;&tau;&alpha;&iota; &gamma;&iota;&alpha;
        &tau;&iota;&sigmaf;
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
        &pi;&omicron;&upsilon; &alpha;&phi;&omicron;&rho;&omicron;ύ&nu;
        &tau;&eta;&nu; &alpha;&pi;&lambda;ή
        &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά
        &phi;&alpha;&kappa;έ&lambda;&omega;&nu;,
        &delta;&epsilon;&mu;ά&tau;&omega;&nu; &kappa;&alpha;&iota;
        &mu;&iota;&kappa;&rho;&omicron;&delta;&epsilon;&mu;ά&tau;&omega;&nu;
        &alpha;&pi;ό &mu;&iota;&alpha;
        &tau;&omicron;&pi;&omicron;&theta;&epsilon;&sigma;ί&alpha;
        &pi;&rho;&omicron;&sigmaf; &mu;&iota;&alpha; ά&lambda;&lambda;&eta;
        &mu;έ&sigma;&alpha; &sigma;&tau;&eta;&nu; &pi;ό&lambda;&eta;.&nbsp;
      </p>
      <p>
        &Eta; &chi;&rho;έ&omega;&sigma;&eta; &tau;&eta;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
        &alpha;&upsilon;&tau;ή&sigmaf; &gamma;ί&nu;&epsilon;&tau;&alpha;&iota;
        &beta;ά&sigma;&epsilon;&iota;
        &sigma;&upsilon;&nu;&omicron;&lambda;&iota;&kappa;ή&sigmaf;
        &chi;&iota;&lambda;&iota;&omicron;&mu;&epsilon;&tau;&rho;&iota;&kappa;ή&sigmaf;
        &alpha;&pi;ό&sigma;&tau;&alpha;&sigma;&eta;&sigmaf; &alpha;&pi;ό
        &tau;&eta;&nu; &alpha;&rho;&chi;&iota;&kappa;ή
        &tau;&omicron;&pi;&omicron;&theta;&epsilon;&sigma;ί&alpha;/&alpha;&phi;&epsilon;&tau;&eta;&rho;ί&alpha;
        έ&omega;&sigmaf; &tau;&omicron;&nu; &tau;&epsilon;&lambda;&iota;&kappa;ό
        &pi;&rho;&omicron;&omicron;&rho;&iota;&sigma;&mu;ό. &Sigma;&epsilon;
        &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
        &pi;&rho;&omicron;&sigma;&theta;ή&kappa;&eta;&sigmaf; &kappa;&iota;
        ά&lambda;&lambda;&omega;&nu; &sigma;&tau;ά&sigma;&epsilon;&omega;&nu;,
        &eta; &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha; &theta;&alpha;
        &upsilon;&pi;&omicron;&lambda;&omicron;&gamma;ί&zeta;&epsilon;&iota;
        &alpha;&upsilon;&tau;ό&mu;&alpha;&tau;&alpha; &tau;&alpha;
        &epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;
        &chi;&iota;&lambda;&iota;ό&mu;&epsilon;&tau;&rho;&alpha;
        &pi;&omicron;&upsilon; &theta;&alpha; &pi;&rho;έ&pi;&epsilon;&iota;
        &nu;&alpha; &delta;&iota;&alpha;&nu;ύ&sigma;&epsilon;&iota; &omicron;
        &delta;&iota;&alpha;&nu;&omicron;&mu;έ&alpha;&sigmaf;
        &kappa;&alpha;&iota; &theta;&alpha;
        &pi;&rho;&omicron;&sigma;&alpha;&rho;&mu;ό&sigma;&epsilon;&iota;
        &tau;&eta;&nu; &tau;&epsilon;&lambda;&iota;&kappa;ή
        &chi;&rho;έ&omega;&sigma;&eta;
        &alpha;&nu;&alpha;&lambda;ό&gamma;&omega;&sigmaf;. &Tau;&omicron;
        &sigma;ύ&nu;&omicron;&lambda;&omicron; &tau;&eta;&sigmaf;
        &chi;&rho;έ&omega;&sigma;&eta;&sigmaf;
        &alpha;&nu;&alpha;&gamma;&rho;ά&phi;&epsilon;&tau;&alpha;&iota;
        &pi;ά&nu;&tau;&alpha; &sigma;&tau;&omicron;
        &tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&omicron;
        &beta;ή&mu;&alpha; &pi;&rho;&iota;&nu; &tau;&eta;&nu;
        &kappa;&alpha;&tau;&alpha;&chi;ώ&rho;&eta;&sigma;&eta;
        &tau;&eta;&sigmaf; &epsilon;&nu;&tau;&omicron;&lambda;ή&sigmaf;
        &gamma;&iota;&alpha;
        &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά.&nbsp;
      </p>
      <p>
        &Eta; &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;
        &alpha;&upsilon;&tau;ή
        &tau;&iota;&mu;&omicron;&lambda;&omicron;&gamma;&epsilon;ί&tau;&alpha;&iota;
        &epsilon;&pi;ί &tau;ό&pi;&omicron;&upsilon;, &mu;ό&nu;&omicron;
        &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ά
        &mu;&epsilon; &tau;&omicron;&upsilon;&sigmaf;
        &tau;&rho;ό&pi;&omicron;&upsilon;&sigmaf;
        &pi;&lambda;&eta;&rho;&omega;&mu;ή&sigmaf; &pi;&omicron;&upsilon;
        &alpha;&nu;&alpha;&phi;έ&rho;&omicron;&nu;&tau;&alpha;&iota;
        &pi;&alpha;&rho;&alpha;&kappa;ά&tau;&omega;.
      </p>
      <p>
        <strong
          >1.3
          &Sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
          Courier Pro -
          &Pi;&rho;&omicron;&sigma;&upsilon;&mu;&phi;&omega;&nu;&eta;&mu;έ&nu;&epsilon;&sigmaf;
          &Delta;&iota;&alpha;&nu;&omicron;&mu;έ&sigmaf; Courier</strong
        >
      </p>
      <p>
        &Pi;&rho;ό&kappa;&epsilon;&iota;&tau;&alpha;&iota; &gamma;&iota;&alpha;
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
        &gamma;&iota;&alpha; &tau;&iota;&sigmaf;
        &omicron;&pi;&omicron;ί&epsilon;&sigmaf; έ&chi;&epsilon;&iota;
        &pi;&rho;&omicron;&eta;&gamma;&eta;&theta;&epsilon;ί
        &kappa;ά&pi;&omicron;&iota;&alpha;
        &epsilon;&mu;&pi;&omicron;&rho;&iota;&kappa;ή
        &sigma;&upsilon;&mu;&phi;&omega;&nu;ί&alpha;
        &mu;&epsilon;&tau;&alpha;&xi;ύ &tau;&eta;&sigmaf; Stoferno.gr
        &kappa;&alpha;&iota; &tau;&eta;&sigmaf;
        &epsilon;&tau;&alpha;&iota;&rho;ί&alpha;&sigmaf; &pi;&omicron;&upsilon;
        &tau;&eta;&nu; &kappa;ά&lambda;&epsilon;&sigma;&epsilon;
        &pi;&rho;&omicron;&sigmaf;
        &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;
        &kappa;ά&pi;&omicron;&iota;&alpha;&sigmaf;
        &kappa;&alpha;&tau;&alpha;&chi;&omega;&rho;&eta;&mu;έ&nu;&eta;&sigmaf;
        &sigma;&tau;&omicron; &sigma;ύ&sigma;&tau;&eta;&mu;&alpha;
        &epsilon;&nu;&tau;&omicron;&lambda;ή&sigmaf;.&nbsp;
      </p>
      <p>
        &Eta; &chi;&rho;έ&omega;&sigma;&eta; &tau;&eta;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
        &alpha;&upsilon;&tau;ή&sigmaf;
        &tau;&iota;&mu;&omicron;&lambda;&omicron;&gamma;&epsilon;ί&tau;&alpha;&iota;
        &kappa;&alpha;&iota; &omicron;&rho;ί&zeta;&epsilon;&tau;&alpha;&iota;
        &kappa;&alpha;&tau;ό&pi;&iota;&nu;
        &sigma;&upsilon;&nu;&epsilon;&nu;&nu;ό&eta;&sigma;&eta;&sigmaf;
        &kappa;&alpha;&iota;
        &alpha;&nu;&alpha;&gamma;&rho;ά&phi;&epsilon;&tau;&alpha;&iota;
        &sigma;&epsilon; &iota;&delta;&iota;&omega;&tau;&iota;&kappa;ό
        &sigma;&upsilon;&mu;&phi;&omega;&nu;&eta;&tau;&iota;&kappa;ό
        &mu;&epsilon;&tau;&alpha;&xi;ύ &tau;&omega;&nu;
        &epsilon;&mu;&pi;&lambda;&epsilon;&kappa;ό&mu;&epsilon;&nu;&omega;&nu;
        &mu;&epsilon;&lambda;ώ&nu;.&nbsp;
      </p>
      <p>
        <strong
          >2. &Tau;&Rho;&Omicron;&Pi;&Omicron;&Iota;
          &Pi;&Lambda;&Eta;&Rho;&Omega;&Mu;&Eta;&Sigma;</strong
        >
      </p>
      <p>
        <strong
          >2.1 &Mu;έ&sigma;&omega;
          &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή&sigmaf;
          /&chi;&rho;&epsilon;&omega;&sigma;&tau;&iota;&kappa;ή&sigmaf;
          &kappa;ά&rho;&tau;&alpha;&sigmaf;</strong
        >
      </p>
      <p>
        &Alpha;&nu; &delta;&iota;&alpha;&theta;έ&tau;&epsilon;&tau;&epsilon;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή/&chi;&rho;&epsilon;&omega;&sigma;&tau;&iota;&kappa;ή
        &kappa;ά&rho;&tau;&alpha; VISA, MasterCard
        &nbsp;&mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
        &tau;&eta;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;
        &mu;&epsilon; &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;
        &mu;έ&sigma;&alpha; &alpha;&pi;ό &tau;&eta;&nu; &phi;ό&rho;&mu;&alpha;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &mu;&alpha;&sigmaf;. &Omicron;&iota;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&epsilon;&sigmaf;
        &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
        &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
        &gamma;ί&nu;&omicron;&nu;&tau;&alpha;&iota;
        &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ά
        &mu;&epsilon;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή/&chi;&rho;&epsilon;&omega;&sigma;&tau;&iota;&kappa;ή
        &kappa;ά&rho;&tau;&alpha;. &Eta; &pi;&lambda;&eta;&rho;&omega;&mu;ή
        &mu;&epsilon;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή/&chi;&rho;&epsilon;&omega;&sigma;&tau;&iota;&kappa;ή
        &nbsp;&kappa;ά&rho;&tau;&alpha; &epsilon;ί&nu;&alpha;&iota;
        &alpha;&pi;ό&lambda;&upsilon;&tau;&alpha;
        &alpha;&sigma;&phi;&alpha;&lambda;ή&sigmaf; &kappa;&alpha;&iota;
        &kappa;&alpha;&lambda;ύ&pi;&tau;&epsilon;&tau;&alpha;&iota; &alpha;&pi;ό
        &tau;&iota;&sigmaf; &pi;&iota;&omicron;
        &sigma;ύ&gamma;&chi;&rho;&omicron;&nu;&epsilon;&sigmaf;
        &pi;&rho;&omicron;&delta;&iota;&alpha;&gamma;&rho;&alpha;&phi;έ&sigmaf;
        &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha;&sigmaf;
        &pi;&omicron;&upsilon; &tau;&eta;&rho;&omicron;ύ&nu;&tau;&alpha;&iota;
        &alpha;&pi;ό &tau;&omicron;
        &tau;&rho;&alpha;&pi;&epsilon;&zeta;&iota;&kappa;ό
        ί&delta;&rho;&upsilon;&mu;&alpha; &mu;&epsilon; &tau;&eta;&nu;
        &epsilon;&pi;&omega;&nu;&upsilon;&mu;ί&alpha;
        &ldquo;&Tau;&Rho;&Alpha;&Pi;&Epsilon;&Zeta;&Alpha;
        &Pi;&Epsilon;&Iota;&Rho;&Alpha;&Iota;&Omega;&Sigma;
        &Alpha;.&Epsilon;.&rdquo;.
        &Sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&alpha;
        &omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &theta;&alpha;
        &omicron;&delta;&eta;&gamma;&epsilon;ί&tau;&alpha;&iota;
        &sigma;&epsilon; &alpha;&sigma;&phi;&alpha;&lambda;ή
        &delta;&iota;&alpha;&kappa;&omicron;&mu;&iota;&sigma;&tau;ή
        &tau;&eta;&sigmaf; &Tau;&rho;ά&pi;&epsilon;&zeta;&alpha;&sigmaf;
        &Pi;&epsilon;&iota;&rho;&alpha;&iota;ώ&sigmaf; A.E. &Sigma;&tau;&eta;
        &sigma;&upsilon;&nu;έ&chi;&epsilon;&iota;&alpha;,
        &delta;ί&nu;&epsilon;&iota; &tau;&alpha;
        &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha; &tau;&eta;&sigmaf;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή&sigmaf;
        &tau;&omicron;&upsilon; &kappa;ά&rho;&tau;&alpha;&sigmaf;
        &kappa;&alpha;&iota; &sigma;&epsilon;
        &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &pi;&omicron;&upsilon;
        &alpha;&upsilon;&tau;ή
        &epsilon;&gamma;&kappa;&rho;&iota;&theta;&epsilon;ί
        &pi;&rho;&omicron;&chi;&omega;&rho;ά &sigma;&tau;&eta;&nu;
        &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta; &tau;&eta;&sigmaf;
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ή&sigmaf;.
        &nbsp;&Gamma;&iota;&alpha; &tau;&eta;&nu;
        &delta;&iota;&epsilon;&kappa;&pi;&epsilon;&rho;&alpha;ί&omega;&sigma;&eta;
        &tau;&eta;&sigmaf;
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ή&sigmaf;
        &theta;&alpha; &sigma;&alpha;&sigmaf;
        &zeta;&eta;&tau;&eta;&theta;&epsilon;ί &omicron;
        &alpha;&rho;&iota;&theta;&mu;ό&sigmaf; CVC / CVV &tau;&eta;&sigmaf;
        &kappa;ά&rho;&tau;&alpha;&sigmaf; &sigma;&alpha;&sigmaf;, &omicron;
        &omicron;&pi;&omicron;ί&omicron;&sigmaf;
        &beta;&rho;ί&sigma;&kappa;&epsilon;&tau;&alpha;&iota;
        &sigma;&tau;&omicron; &pi;ί&sigma;&omega; &mu;έ&rho;&omicron;&sigmaf;
        &tau;&eta;&sigmaf;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή&sigmaf;
        &sigma;&alpha;&sigmaf; &kappa;ά&rho;&tau;&alpha;&sigmaf;
        &kappa;&alpha;&iota; &epsilon;ί&nu;&alpha;&iota; έ&nu;&alpha;&sigmaf;
        &tau;&rho;&iota;&psi;ή&phi;&iota;&omicron;&sigmaf;
        &alpha;&rho;&iota;&theta;&mu;ό&sigmaf;. &Sigma;&alpha;&sigmaf;
        &zeta;&eta;&tau;&epsilon;ί&tau;&alpha;&iota; &gamma;&iota;&alpha;
        &epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;
        &alpha;&sigma;&phi;ά&lambda;&epsilon;&iota;&alpha; &kappa;&alpha;&iota;
        &sigma;&alpha;&sigmaf;
        &pi;&rho;&omicron;&sigma;&tau;&alpha;&tau;&epsilon;ύ&epsilon;&iota;
        &alpha;&kappa;ό&mu;&alpha;
        &pi;&epsilon;&rho;&iota;&sigma;&sigma;ό&tau;&epsilon;&rho;&omicron;
        &mu;&iota;&alpha;&sigmaf; &kappa;&alpha;&iota;
        &epsilon;ί&nu;&alpha;&iota; &pi;&omicron;&lambda;ύ
        &delta;ύ&sigma;&kappa;&omicron;&lambda;&omicron; &gamma;&iota;&alpha;
        &kappa;ά&pi;&omicron;&iota;&omicron;&nu; &nu;&alpha;
        &gamma;&nu;&omega;&rho;ί&zeta;&epsilon;&iota; &tau;&omicron;
        &pi;&epsilon;&delta;ί&omicron; &alpha;&upsilon;&tau;ό.
        &Gamma;&iota;&alpha; &nu;&alpha; &tau;&omicron;
        &epsilon;&iota;&sigma;ά&gamma;&epsilon;&tau;&epsilon;,
        &gamma;&upsilon;&rho;ί&sigma;&tau;&epsilon; &kappa;&alpha;&iota;
        &kappa;&omicron;&iota;&tau;ά&xi;&tau;&epsilon; &tau;&omicron;
        &pi;ί&sigma;&omega; &mu;έ&rho;&omicron;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή&sigmaf;
        &sigma;&alpha;&sigmaf; &kappa;ά&rho;&tau;&alpha;&sigmaf;.
        &Alpha;&pi;&omicron;&tau;&epsilon;&lambda;&epsilon;ί &tau;&omicron;&nu;
        &tau;&rho;&iota;&psi;ή&phi;&iota;&omicron;
        &alpha;&rho;&iota;&theta;&mu;ό &pi;&omicron;&upsilon; &theta;&alpha;
        &delta;&epsilon;ί&tau;&epsilon; &kappa;&alpha;&iota;
        &sigma;&upsilon;&nu;ή&theta;&omega;&sigmaf;
        &beta;&rho;ί&sigma;&kappa;&epsilon;&tau;&alpha;&iota; &pi;ά&nu;&omega;
        &delta;&epsilon;&xi;&iota;ά. &Sigma;&tau;&omicron; &pi;&omicron;&sigma;ό
        &tau;&eta;&sigmaf;
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ή&sigmaf;
        &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&epsilon;&tau;&alpha;&iota;
        &epsilon;&kappa;&tau;ό&sigmaf; &alpha;&pi;ό &tau;&eta;&nu;
        &alpha;&xi;ί&alpha; &tau;&omicron;&upsilon;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omicron;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &kappa;&alpha;&iota; &tau;&omicron;
        &kappa;ό&sigma;&tau;&omicron;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&eta;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
        &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά&sigmaf; &tau;&omega;&nu;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &tau;&eta;&sigmaf;
        &epsilon;&pi;&iota;&chi;&epsilon;ί&rho;&eta;&sigma;&eta;&sigmaf;
        &sigma;&tau;&omicron;&nu; &tau;&epsilon;&lambda;&iota;&kappa;ό
        &chi;&rho;ή&sigma;&tau;&eta;, &tau;&omicron;
        &omicron;&pi;&omicron;ί&omicron; &epsilon;&phi; &epsilon;&xi;ή&sigmaf;
        &omicron;&rho;ί&zeta;&epsilon;&tau;&alpha;&iota; &omega;&sigmaf;
        &ldquo;delivery fee&rdquo;, ό&pi;&omega;&sigmaf; &alpha;&upsilon;&tau;ό
        &alpha;&nu;&alpha;&gamma;&rho;ά&phi;&epsilon;&tau;&alpha;&iota;
        &sigma;&tau;&eta;&nu;
        &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
        &phi;ό&rho;&mu;&alpha;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;.
        &nbsp;&Tau;&omicron; Stoferno.gr έ&chi;&epsilon;&iota; &tau;&omicron;
        &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha; &nu;&alpha;
        &delta;&epsilon;&sigma;&mu;&epsilon;ύ&sigma;&epsilon;&iota;
        &tau;&omicron; &chi;&rho;&eta;&mu;&alpha;&tau;&iota;&kappa;ό
        &pi;&omicron;&sigma;ό &epsilon;&kappa;
        &mu;έ&rho;&omicron;&upsilon;&sigmaf; &tau;&omega;&nu;
        &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&tau;ώ&nu;
        &tau;&eta;&sigmaf; &alpha;&pi;ό &tau;&eta;&nu; &kappa;ά&rho;&tau;&alpha;
        &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;,
        &mu;ό&lambda;&iota;&sigmaf;
        &sigma;&tau;&epsilon;ί&lambda;&epsilon;&iota; &omicron;
        &chi;&rho;ή&sigma;&tau;&eta;&sigmaf; &tau;&eta;&nu;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;.
      </p>
      <p>
        &Tau;&alpha; &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&alpha;
        &tau;&eta;&sigmaf;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή&sigmaf;/&chi;&rho;&epsilon;&omega;&sigma;&tau;&iota;&kappa;ή&sigmaf;
        &kappa;ά&rho;&tau;&alpha;&sigmaf; &tau;&omicron;&upsilon;
        &chi;&rho;ή&sigma;&tau;&eta; &delta;&epsilon;&nu;
        &alpha;&pi;&omicron;&theta;&eta;&kappa;&epsilon;ύ&omicron;&nu;&tau;&alpha;&iota;
        &sigma;&tau;&eta; &beta;ά&sigma;&eta;
        &delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu;
        &tau;&omicron;&upsilon; Stoferno.gr. &Sigma;&tau;&eta;
        &sigma;&upsilon;&nu;έ&chi;&epsilon;&iota;&alpha;,
        &epsilon;&pi;&iota;&sigma;&tau;&rho;έ&phi;&epsilon;&iota;
        &sigma;&tau;&eta;&nu;
        &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;
        &mu;&alpha;&sigmaf; &kappa;&alpha;&iota;
        &epsilon;&iota;&delta;&omicron;&pi;&omicron;&iota;&epsilon;ί&tau;&alpha;&iota;
        &gamma;&iota;&alpha; &tau;&eta;&nu;
        &epsilon;&pi;&iota;&tau;&upsilon;&chi;ή ή &mu;&eta;
        έ&kappa;&beta;&alpha;&sigma;&eta; &tau;&eta;&sigmaf;
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;ή&sigmaf;.
      </p>
      <p><strong>2.2 Mέ&sigma;&omega; PayPal</strong></p>
      <p>
        &Mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
        &pi;&lambda;&eta;&rho;ώ&sigma;&epsilon;&tau;&epsilon; &mu;&epsilon;
        PayPal. H &alpha;&gamma;&omicron;&rho;ά &sigma;&alpha;&sigmaf;
        &theta;&alpha; &chi;&rho;&epsilon;&omega;&theta;&epsilon;ί
        &sigma;&tau;&eta;&nu; &kappa;ά&rho;&tau;&alpha; ή &tau;&omicron;&nu;
        &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;ό
        &pi;&omicron;&upsilon; &theta;&alpha;
        &upsilon;&pi;&omicron;&beta;ά&lambda;&lambda;&epsilon;&tau;&epsilon;
        &mu;&epsilon; &tau;&eta;&nu;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
        &sigma;&alpha;&sigmaf;. &Alpha;&nu;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&sigma;&epsilon;&tau;&epsilon;
        &tau;&eta;&nu; PayPal,
        &sigma;&upsilon;&nu;&alpha;&iota;&nu;&epsilon;ί&tau;&epsilon;
        &sigma;&tau;&eta;&nu; &chi;&rho;ή&sigma;&eta; &tau;&omega;&nu;
        &sigma;&tau;&omicron;&iota;&chi;&epsilon;ί&omega;&nu;
        &sigma;&alpha;&sigmaf; &alpha;&pi;ό &tau;&eta;&nu; PayPal
        ό&pi;&omega;&sigmaf;
        &alpha;&nu;&alpha;&phi;έ&rho;&epsilon;&tau;&alpha;&iota;
        &sigma;&tau;&eta;&nu; &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
        &Pi;&rho;&omicron;&sigma;&tau;&alpha;&sigma;ί&alpha;&sigmaf;
        &Delta;&epsilon;&delta;&omicron;&mu;έ&nu;&omega;&nu; &tau;&eta;&sigmaf;
        PayPal.
      </p>
      <p>
        &Sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&alpha;
        &sigma;&tau;&eta;&nu; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
        &tau;&eta;&sigmaf;
        &alpha;&nu;&tau;&iota;&kappa;&alpha;&tau;ά&sigma;&tau;&alpha;&sigma;&eta;&sigmaf;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &lambda;ό&gamma;&omega;
        έ&lambda;&lambda;&epsilon;&iota;&psi;&eta;&sigmaf;
        ή/&kappa;&alpha;&iota; &mu;&eta;
        &delta;&iota;&alpha;&theta;&epsilon;&sigma;&iota;&mu;ό&tau;&eta;&tau;&alpha;&sigmaf;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu;, &tau;&omicron;
        &kappa;&alpha;&tau;ά&sigma;&tau;&eta;&mu;&alpha;
        &pi;&rho;&omicron;&tau;&epsilon;ί&nu;&epsilon;&iota;
        &sigma;&tau;&omicron; &chi;&rho;ή&sigma;&tau;&eta;
        &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; &pi;&rho;&omicron;&sigmaf;
        &alpha;&nu;&tau;&iota;&kappa;&alpha;&tau;ά&sigma;&tau;&alpha;&sigma;&eta;.
        &Alpha;&nu; &omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
        &delta;&epsilon;&nu; &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί
        &kappa;ά&pi;&omicron;&iota;&omicron; &alpha;&pi;ό &tau;&alpha;
        &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&alpha;
        &pi;&rho;&omicron;&tau;&epsilon;&iota;&nu;ό&mu;&epsilon;&nu;&alpha;
        &pi;&rho;&omicron;ϊό&nu;&tau;&alpha;, &tau;&omicron; &mu;&eta;
        &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&omicron;
        &pi;&rho;&omicron;ϊό&nu;
        &alpha;&phi;&alpha;&iota;&rho;&epsilon;ί&tau;&alpha;&iota; &alpha;&pi;ό
        &tau;&eta;&nu;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
        &kappa;&alpha;&iota; &omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
        &alpha;&pi;&omicron;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
        &tau;&eta;&nu; &pi;&lambda;&eta;&rho;&omega;&mu;ή &tau;&eta;&sigmaf;
        &alpha;&xi;ί&alpha;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &pi;έ&rho;&alpha;&nu; &tau;&eta;&sigmaf; &alpha;&xi;ί&alpha;&sigmaf;
        &tau;&omicron;&upsilon;
        &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&omicron;&upsilon;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omicron;&sigmaf; &kappa;&alpha;&iota;
        &tau;&omicron; &kappa;ό&sigma;&tau;&omicron;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&eta;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
        &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά&sigmaf; &tau;&omega;&nu;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &tau;&eta;&sigmaf;
        &epsilon;&pi;&iota;&chi;&epsilon;ί&rho;&eta;&sigma;&eta;&sigmaf;
        &sigma;&tau;&omicron;&nu; &tau;&epsilon;&lambda;&iota;&kappa;ό
        &chi;&rho;ή&sigma;&tau;&eta;, &tau;&omicron;
        &omicron;&pi;&omicron;ί&omicron; &epsilon;&phi; &epsilon;&xi;ή&sigmaf;
        &omicron;&rho;ί&zeta;&epsilon;&tau;&alpha;&iota; &omega;&sigmaf;
        &ldquo;delivery fee&rdquo;, ό&pi;&omega;&sigmaf; &alpha;&upsilon;&tau;ό
        &alpha;&nu;&alpha;&gamma;&rho;ά&phi;&epsilon;&tau;&alpha;&iota;
        &sigma;&tau;&eta;&nu;
        &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
        &phi;ό&rho;&mu;&alpha;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;.
      </p>
      <p>
        &Sigma;&epsilon; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
        &pi;&omicron;&upsilon; &delta;&epsilon;&nu;
        &epsilon;&xi;&upsilon;&pi;&eta;&rho;&epsilon;&tau;&eta;&theta;&epsilon;ί
        &eta; &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
        &sigma;&alpha;&sigmaf; &kappa;&alpha;&iota;
        έ&chi;&epsilon;&tau;&epsilon;
        &pi;&lambda;&eta;&rho;ώ&sigma;&epsilon;&iota; &mu;&epsilon;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή/&chi;&rho;&epsilon;&omega;&sigma;&tau;&iota;&kappa;ή
        &kappa;ά&rho;&tau;&alpha;, &tau;&alpha;
        &chi;&rho;ή&mu;&alpha;&tau;&alpha; &sigma;ά&sigmaf;
        &epsilon;&pi;&iota;&sigma;&tau;&rho;έ&phi;&omicron;&nu;&tau;&alpha;&iota;.
        &Eta; &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;ή
        &gamma;ί&nu;&epsilon;&tau;&alpha;&iota; &alpha;&pi;ό &tau;&eta;&nu;
        &nbsp;&tau;&rho;ά&pi;&epsilon;&zeta;&alpha;
        &Pi;&epsilon;&iota;&rho;&alpha;&iota;ώ&sigmaf; AE, &eta;
        &omicron;&pi;&omicron;ί&alpha; &kappa;&alpha;&iota;
        &phi;έ&rho;&epsilon;&iota; &tau;&eta;&nu;
        &mu;&omicron;&nu;&alpha;&delta;&iota;&kappa;ή
        &epsilon;&upsilon;&theta;ύ&nu;&eta; &gamma;&iota;&alpha; &tau;&eta;&nu;
        &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;ή &tau;&omega;&nu;
        &chi;&rho;&eta;&mu;ά&tau;&omega;&nu; &sigma;&tau;&eta;&nu;
        &kappa;ά&rho;&tau;&alpha; &pi;&omicron;&upsilon;
        &chi;&rho;&eta;&sigma;&iota;&mu;&omicron;&pi;&omicron;&iota;ή&theta;&eta;&kappa;&epsilon;
        &sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&eta;&nu;
        &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή &tau;&eta;&sigmaf;
        &gamma;&iota;&alpha; &tau;&eta;&nu;
        &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;ή
        &chi;&rho;&eta;&mu;ά&tau;&omega;&nu; &alpha;&pi;ό
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
        &pi;&epsilon;&lambda;&alpha;&tau;ώ&nu; &tau;&eta;&sigmaf;. &Omicron;
        &kappa;&alpha;&tau;&alpha;&nu;&alpha;&lambda;&omega;&tau;ή&sigmaf;
        &epsilon;&pi;&iota;&beta;&alpha;&rho;ύ&nu;&epsilon;&tau;&alpha;&iota;
        &sigma;&rsquo; &alpha;&upsilon;&tau;ή &tau;&eta;&nu;
        &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &gamma;&iota;&alpha;
        &tau;&eta;&nu; &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;ή
        &chi;&rho;&eta;&mu;ά&tau;&omega;&nu; ή ό&chi;&iota;
        &nbsp;&sigma;ύ&mu;&phi;&omega;&nu;&alpha; &mu;&epsilon; &tau;&eta;&nu;
        &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
        &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;ώ&nu;
        &tau;&eta;&sigmaf; &tau;&rho;ά&pi;&epsilon;&zeta;&alpha;&sigmaf;
        &mu;&epsilon; &tau;&eta;&nu; &omicron;&pi;&omicron;ί&alpha;
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;ά&sigma;&sigma;&epsilon;&tau;&alpha;&iota;.
        &Eta; &mu;&eta;
        &epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &sigma;&upsilon;&nu;ί&sigma;&tau;&alpha;&tau;&alpha;&iota;
        &sigma;&tau;&eta; &mu;&eta;
        &pi;&alpha;&rho;ά&delta;&omicron;&sigma;&eta; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &alpha;&pi;ό &tau;&omicron;
        &kappa;&alpha;&tau;ά&sigma;&tau;&eta;&mu;&alpha; &alpha;&pi;ό
        &delta;&iota;&alpha;&pi;ί&sigma;&tau;&omega;&sigma;&eta;
        έ&lambda;&lambda;&epsilon;&iota;&psi;&eta;&sigmaf;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omicron;&sigmaf; ή
        &alpha;&delta;&upsilon;&nu;&alpha;&mu;ί&alpha; &tau;&omicron;&upsilon;
        &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&omicron;&sigmaf;
        &nu;&alpha;
        &epsilon;&xi;&upsilon;&pi;&eta;&rho;&epsilon;&tau;ή&sigma;&epsilon;&iota;
        &tau;&eta;&nu;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
        &kappa;&alpha;&iota; &pi;&alpha;&rho;ά&lambda;&lambda;&eta;&lambda;&eta;
        ά&rho;&nu;&eta;&sigma;&eta; &tau;&omicron;&upsilon;
        &pi;&epsilon;&lambda;ά&tau;&eta; &nu;&alpha;
        &epsilon;&xi;&upsilon;&pi;&eta;&rho;&epsilon;&tau;&eta;&theta;&epsilon;ί
        &sigma;&epsilon;
        &mu;&epsilon;&tau;&alpha;&gamma;&epsilon;&nu;έ&sigma;&tau;&epsilon;&rho;&omicron;
        &chi;&rho;ό&nu;&omicron; (ό&tau;&alpha;&nu; &delta;&eta;&lambda;.
        &tau;&omicron; &epsilon;&mu;&pi;ό&rho;&epsilon;&upsilon;&mu;&alpha;
        &theta;&alpha; &epsilon;ί&nu;&alpha;&iota;
        &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&omicron;),
        &kappa;&alpha;&iota; &sigma;&upsilon;&nu;&epsilon;&pi;ώ&sigmaf;
        &sigma;&tau;&eta;&nu; &alpha;&delta;&upsilon;&nu;&alpha;&mu;ί&alpha;
        &omicron;&lambda;&omicron;&kappa;&lambda;ή&rho;&omega;&sigma;&eta;&sigmaf;
        &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &kappa;&alpha;&iota; &sigma;&epsilon; &kappa;&alpha;&mu;ί&alpha;
        &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &delta;&epsilon;&nu;
        &epsilon;&nu;&nu;&omicron;&epsilon;ί&tau;&alpha;&iota; &eta; &mu;&eta;
        &iota;&kappa;&alpha;&nu;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
        &tau;&omicron;&upsilon; &pi;&epsilon;&lambda;ά&tau;&eta; &mu;&epsilon;
        &tau;&eta;&nu;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
        &gamma;&iota;&alpha; &tau;&eta;&nu; &omicron;&pi;&omicron;ί&alpha;
        &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ή
        &epsilon;&upsilon;&theta;ύ&nu;&eta; &phi;έ&rho;&epsilon;&iota;
        &tau;&omicron; &kappa;&alpha;&tau;ά&sigma;&tau;&eta;&mu;&alpha;.
        &Sigma;&tau;&eta;&nu; ί&delta;&iota;&alpha; &alpha;&upsilon;&tau;ή
        &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &omicron;
        &pi;&epsilon;&lambda;ά&tau;&eta;&sigmaf;
        &epsilon;&pi;&iota;&beta;&alpha;&rho;ύ&nu;&epsilon;&tau;&alpha;&iota;
        &mu;&epsilon; &tau;&omicron;
        &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
        &omicron;&rho;&iota;&zeta;ό&mu;&epsilon;&nu;&omicron;
        &beta;ά&sigma;&epsilon;&iota; &tau;&eta;&sigmaf;
        &tau;&iota;&mu;&omicron;&lambda;&omicron;&gamma;&iota;&alpha;&kappa;ή&sigmaf;
        &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή&sigmaf;
        &tau;&eta;&sigmaf;
        &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
        &pi;&omicron;&sigma;ό, &tau;&omicron; &omicron;&pi;&omicron;ί&omicron;
        &alpha;&phi;&omicron;&rho;ά &tau;&eta; &delta;&alpha;&pi;ά&nu;&eta;
        &mu;&epsilon;&tau;ά&beta;&alpha;&sigma;&eta;&sigmaf;
        &tau;&omicron;&upsilon; &delta;&iota;&alpha;&nu;&omicron;&mu;έ&alpha;
        &sigma;&tau;&omicron; &kappa;&alpha;&tau;ά&sigma;&tau;&eta;&mu;&alpha;
        &tau;&omicron; &omicron;&pi;&omicron;ί&omicron;
        &kappa;&rho;&alpha;&tau;&epsilon;ί&tau;&alpha;&iota;
        &epsilon;&phi;ά&pi;&alpha;&xi; &alpha;&pi;ό &tau;&omicron;
        &pi;&omicron;&sigma;ό &pi;&omicron;&upsilon; έ&chi;&epsilon;&iota;
        &kappa;&alpha;&tau;&alpha;&beta;&lambda;&eta;&theta;&epsilon;ί
        &mu;έ&sigma;&omega;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή&sigmaf;/&chi;&rho;&epsilon;&omega;&sigma;&tau;&iota;&kappa;ή&sigmaf;
        &kappa;ά&rho;&tau;&alpha;&sigmaf; &kappa;&alpha;&iota;
        &epsilon;&pi;&iota;&sigma;&tau;&rho;έ&phi;&epsilon;&tau;&alpha;&iota;
        &tau;&omicron; &upsilon;&pi;ό&lambda;&omicron;&iota;&pi;&omicron;.
      </p>
      <p>
        <strong
          >2.3 Mέ&sigma;&omega;
          &Tau;&rho;&alpha;&pi;&epsilon;&zeta;&iota;&kappa;ή&sigmaf;
          &kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta;&sigmaf;</strong
        >
      </p>
      <p>
        &Gamma;&iota;&alpha; &tau;&eta;&nu; &chi;&rho;ή&sigma;&eta;
        &tau;&eta;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf; Stoferno
        Courier, &mu;&pi;&omicron;&rho;&epsilon;ί&tau;&epsilon; &nu;&alpha;
        &pi;&lambda;&eta;&rho;ώ&sigma;&epsilon;&tau;&epsilon; &mu;&epsilon;
        &alpha;&pi;&lambda;ή &tau;&rho;&alpha;&pi;&epsilon;&zeta;&iota;&kappa;ή
        &kappa;&alpha;&tau;ά&theta;&epsilon;&sigma;&eta; &sigma;&epsilon;
        έ&nu;&alpha; &alpha;&pi;ό &tau;&alpha;
        &pi;&alpha;&rho;&alpha;&kappa;ά&tau;&omega; &Iota;&Beta;&Alpha;&Nu;
        &pi;&omicron;&upsilon;
        &alpha;&nu;&omicron;ί&kappa;&omicron;&upsilon;&nu; &sigma;&tau;&eta;&nu;
        &epsilon;&tau;&alpha;&iota;&rho;ί&alpha; Stoferno.gr:
      </p>
      <p>
        &Omicron; &tau;&rho;ό&pi;&omicron;&sigmaf;
        &pi;&lambda;&eta;&rho;&omega;&mu;ή&sigmaf;
        &alpha;&upsilon;&tau;ό&sigmaf; &iota;&sigma;&chi;ύ&epsilon;&iota;
        &mu;ό&nu;&omicron; &gamma;&iota;&alpha; &tau;&eta;&nu;
        &epsilon;&xi;ό&phi;&lambda;&eta;&sigma;&eta; έ&rho;&gamma;&omega;&nu;
        Courier &kappa;&alpha;&iota; ό&chi;&iota; &gamma;&iota;&alpha;
        &tau;&eta;&nu; &pi;&lambda;&eta;&rho;&omega;&mu;ή &tau;&omega;&nu;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;&iota;ώ&nu;
        &mu;έ&sigma;&omega; &tau;&omicron;&upsilon; Marketplace
        &epsilon;&pi;&iota;&chi;&epsilon;&iota;&rho;ή&sigma;&epsilon;&omega;&nu;.
        &Gamma;&iota;&alpha; &tau;&eta;&nu; &pi;&lambda;&eta;&rho;&omega;&mu;ή
        &tau;&omega;&nu;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;&iota;ώ&nu;
        Marketplace,
        &alpha;&kappa;&omicron;&lambda;&omicron;&upsilon;&theta;ή&sigma;&tau;&epsilon;
        &tau;&eta; &mu;έ&theta;&omicron;&delta;&omicron; 2&alpha;
        &kappa;&alpha;&iota; 2&beta;.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong
          >3. &Pi;&Omicron;&Lambda;&Iota;&Tau;&Iota;&Kappa;&Eta;
          &Epsilon;&Pi;&Iota;&Sigma;&Tau;&Rho;&Omicron;&Phi;&Eta;&Sigma;
          &Chi;&Rho;&Eta;&Mu;&Alpha;&Tau;&Omega;&Nu;</strong
        >
      </p>
      <p>
        <strong
          >3.1
          &Sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
          Marketplace</strong
        >
      </p>
      <p>
        &Eta; &epsilon;&tau;&alpha;&iota;&rho;ί&alpha; &mu;&epsilon;
        &tau;&eta;&nu; &epsilon;&pi;&omega;&nu;&upsilon;&mu;ί&alpha;
        &laquo;Stoferno IKE&raquo; &epsilon;&nu;&epsilon;&rho;&gamma;&epsilon;ί
        &omega;&sigmaf;
        &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;ά&tau;&eta;&sigmaf;
        &kappa;&alpha;&tau;&alpha;&sigma;&tau;&eta;&mu;ά&tau;&omega;&nu;,
        &pi;&alpha;&rho;έ&chi;&omicron;&nu;&tau;&alpha;&sigmaf;
        &sigma;&tau;&omicron;&upsilon;&sigmaf;
        &kappa;&alpha;&tau;&alpha;&nu;&alpha;&lambda;&omega;&tau;έ&sigmaf;
        &tau;&eta;&nu; &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;
        &tau;&eta;&sigmaf; ά&mu;&epsilon;&sigma;&eta;&sigmaf;
        &epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;&sigmaf;
        &kappa;&alpha;&iota;
        &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά&sigmaf; &tau;&omega;&nu;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;&iota;ώ&nu;
        &tau;&omicron;&upsilon;&sigmaf; &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
        &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;ή&sigmaf;
        &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;&sigmaf;
        &tau;&eta;&sigmaf;
        &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;&sigmaf;
        &mu;έ&sigma;&alpha; &alpha;&pi;ό έ&nu;&alpha;
        &delta;ί&kappa;&tau;&upsilon;&omicron;
        &alpha;&nu;&epsilon;&xi;ά&rho;&tau;&eta;&tau;&omega;&nu;
        &delta;&iota;&alpha;&nu;&omicron;&mu;έ&omega;&nu;. &Omicron;
        &Pi;&epsilon;&lambda;ά&tau;&eta;&sigmaf;, &delta;&iota;&alpha;
        &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
        &pi;&lambda;&alpha;&tau;&phi;ό&rho;&mu;&alpha;&sigmaf;
        &laquo;Stoferno.gr&raquo;,
        &epsilon;&xi;&omicron;&upsilon;&sigma;&iota;&omicron;&delta;&omicron;&tau;&epsilon;ί
        &tau;&eta;&nu; &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
        &nu;&alpha; &chi;&rho;&epsilon;ώ&nu;&epsilon;&iota;
        &tau;&omicron;&upsilon;&sigmaf;
        &lambda;&omicron;&gamma;&alpha;&rho;&iota;&alpha;&sigma;&mu;&omicron;ύ&sigmaf;
        &tau;&eta;&sigmaf;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή&sigmaf;/&chi;&rho;&epsilon;&omega;&sigma;&tau;&iota;&kappa;ή&sigmaf;
        &tau;&omicron;&upsilon; &kappa;ά&rho;&tau;&alpha;&sigmaf;
        &gamma;&iota;&alpha;
        &omicron;&pi;&omicron;&iota;&omicron;&delta;ή&pi;&omicron;&tau;&epsilon;
        &pi;&omicron;&sigma;ό &mu;&pi;&omicron;&rho;&epsilon;ί &nu;&alpha;
        &omicron;&phi;&epsilon;ί&lambda;&epsilon;&tau;&alpha;&iota;
        &sigma;&tau;&eta;&nu;
        &Epsilon;&Tau;&Alpha;&Iota;&Rho;&Epsilon;&Iota;&Alpha; ή
        &sigma;&tau;&alpha;
        &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&alpha;
        &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&alpha;,
        &alpha;&phi;&omicron;ύ
        &pi;&rho;&omicron;&eta;&gamma;&omicron;&upsilon;&mu;έ&nu;&omega;&sigmaf;,
        &pi;&alpha;&rho;έ&chi;&epsilon;&iota; &tau;&eta;&nu; &rho;&eta;&tau;ή
        &sigma;&upsilon;&nu;&alpha;ί&nu;&epsilon;&sigma;ή
        &tau;&omicron;&upsilon; &sigma;&tau;&eta;&nu;
        &alpha;&nu;&omega;&tau;έ&rho;&omega; &chi;&rho;έ&omega;&sigma;&eta;
        &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &tau;&omicron;&upsilon;.
      </p>
      <p>
        &Sigma;&epsilon; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
        &pi;&omicron;&upsilon; &eta;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
        &tau;&epsilon;&lambda;&iota;&kappa;ώ&sigmaf; &delta;&epsilon;&nu;
        &epsilon;&xi;&upsilon;&pi;&eta;&rho;&epsilon;&tau;&eta;&theta;&epsilon;ί,
        &eta; &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &theta;&alpha;
        &epsilon;&pi;&iota;&sigma;&tau;&rho;έ&psi;&epsilon;&iota;
        &sigma;&tau;&omicron;&nu; &chi;&rho;ή&sigma;&tau;&eta; &tau;&alpha;
        &chi;&rho;ή&mu;&alpha;&tau;&alpha; &pi;&omicron;&upsilon;
        &kappa;&alpha;&tau;έ&beta;&alpha;&lambda;&lambda;&epsilon; &mu;&epsilon;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή/&chi;&rho;&epsilon;&omega;&sigma;&tau;&iota;&kappa;ή
        &kappa;ά&rho;&tau;&alpha; &mu;έ&sigma;&omega; &tau;&eta;&sigmaf;
        &iota;&sigma;&tau;&omicron;&sigma;&epsilon;&lambda;ί&delta;&alpha;&sigmaf;
        &laquo;stoferno.gr&raquo;, &kappa;&alpha;&iota; &tau;&alpha;
        &omicron;&pi;&omicron;ί&alpha; &alpha;&phi;&omicron;&rho;&omicron;ύ&nu;
        &tau;&omicron; &kappa;ό&sigma;&tau;&omicron;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &pi;&omicron;&upsilon; &delta;&epsilon;&nu;
        &epsilon;&kappa;&tau;&epsilon;&lambda;έ&sigma;&theta;&eta;&kappa;&epsilon;
        ό&pi;&omega;&sigmaf; &alpha;&upsilon;&tau;ή &epsilon;ί&chi;&epsilon;
        &kappa;&alpha;&theta;&omicron;&rho;&iota;&sigma;&tau;&epsilon;ί
        &alpha;&pi;ό &tau;&omicron;
        &sigma;&upsilon;&nu;&epsilon;&rho;&gamma;&alpha;&zeta;ό&mu;&epsilon;&nu;&omicron;
        &kappa;&alpha;&tau;ά&sigma;&tau;&eta;&mu;&alpha;,
        &kappa;&alpha;&theta;ώ&sigmaf; &kappa;&alpha;&iota; &tau;&omicron;
        &kappa;ό&sigma;&tau;&omicron;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&eta;&sigmaf;
        &alpha;&pi;ό &tau;&eta;&nu;
        &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
        &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά&sigmaf; .&nbsp;
      </p>
      <p>
        &Gamma;&iota;&alpha; &tau;&eta;&nu; &omicron;&rho;&theta;ή
        &pi;&lambda;&eta;&rho;&omega;&mu;ή &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &sigma;&alpha;&sigmaf;
        &sigma;&upsilon;&mu;&pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;&alpha;&nu;&omicron;&mu;έ&nu;&omega;&nu;
        &tau;&omega;&nu; &epsilon;&iota;&delta;&iota;&kappa;ώ&nu;
        &pi;&epsilon;&rho;&iota;&pi;&tau;ώ&sigma;&epsilon;&omega;&nu;
        &alpha;&nu;&tau;&iota;&kappa;&alpha;&tau;ά&sigma;&tau;&alpha;&sigma;&eta;&sigmaf;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; ή/&kappa;&alpha;&iota;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu;
        &mu;&epsilon;&tau;&alpha;&beta;&lambda;&eta;&tau;&omicron;ύ
        &beta;ά&rho;&omicron;&upsilon;&sigmaf;, &tau;&omicron; Stoferno.gr
        έ&chi;&epsilon;&iota; &delta;&iota;&kappa;&alpha;ί&omega;&mu;&alpha;
        &nu;&alpha; &delta;&epsilon;&sigma;&mu;&epsilon;ύ&sigma;&epsilon;&iota;
        15% &epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu; &tau;&eta;&sigmaf;
        &sigma;&upsilon;&nu;&omicron;&lambda;&iota;&kappa;ή&sigmaf;
        &alpha;&xi;ί&alpha;&sigmaf; &tau;&omega;&nu;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &alpha;&pi;ό &tau;&eta;&nu; &kappa;ά&rho;&tau;&alpha;
        &tau;&omicron;&upsilon; &chi;&rho;ή&sigma;&tau;&eta;.
        &Epsilon;&phi;ό&sigma;&omicron;&nu;
        &pi;&rho;&omicron;&kappa;ύ&psi;&epsilon;&iota; &eta;
        &tau;&epsilon;&lambda;&iota;&kappa;ή &alpha;&xi;ί&alpha;
        &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;,
        &tau;&omicron; &epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;
        &pi;&omicron;&sigma;ό &pi;έ&rho;&alpha;&nu; &tau;&eta;&sigmaf;
        &alpha;&xi;ί&alpha;&sigmaf; &alpha;&upsilon;&tau;ή&sigmaf;
        &theta;&alpha; &epsilon;&pi;&iota;&sigma;&tau;&rho;έ&psi;&epsilon;&iota;
        &sigma;&tau;&eta;&nu; &kappa;ά&rho;&tau;&alpha; &tau;&omicron;&upsilon;
        &chi;&rho;ή&sigma;&tau;&eta;.&nbsp;
      </p>
      <p>
        &Sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&alpha;
        &sigma;&tau;&eta;&nu; &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta;
        &tau;&eta;&sigmaf;
        &alpha;&nu;&tau;&iota;&kappa;&alpha;&tau;ά&sigma;&tau;&alpha;&sigma;&eta;&sigmaf;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &lambda;ό&gamma;&omega;
        έ&lambda;&lambda;&epsilon;&iota;&psi;&eta;&sigmaf;
        ή/&kappa;&alpha;&iota; &mu;&eta;
        &delta;&iota;&alpha;&theta;&epsilon;&sigma;&iota;&mu;ό&tau;&eta;&tau;&alpha;&sigmaf;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu;, &tau;&omicron;
        &kappa;&alpha;&tau;ά&sigma;&tau;&eta;&mu;&alpha;
        &pi;&rho;&omicron;&tau;&epsilon;ί&nu;&epsilon;&iota;
        &sigma;&tau;&omicron; &chi;&rho;ή&sigma;&tau;&eta;
        &pi;&rho;&omicron;ϊό&nu;&tau;&alpha; &pi;&rho;&omicron;&sigmaf;
        &alpha;&nu;&tau;&iota;&kappa;&alpha;&tau;ά&sigma;&tau;&alpha;&sigma;&eta;.
        &Alpha;&nu; &omicron; &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
        &delta;&epsilon;&nu; &epsilon;&pi;&iota;&theta;&upsilon;&mu;&epsilon;ί
        &kappa;ά&pi;&omicron;&iota;&omicron; &alpha;&pi;ό &tau;&alpha;
        &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&alpha;
        &pi;&rho;&omicron;&tau;&epsilon;&iota;&nu;ό&mu;&epsilon;&nu;&alpha;
        &pi;&rho;&omicron;ϊό&nu;&tau;&alpha;, &tau;&omicron; &mu;&eta;
        &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&omicron;
        &pi;&rho;&omicron;ϊό&nu;
        &alpha;&phi;&alpha;&iota;&rho;&epsilon;ί&tau;&alpha;&iota; &alpha;&pi;ό
        &tau;&eta;&nu;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
        &kappa;&alpha;&iota;
        &alpha;&pi;&omicron;&zeta;&eta;&mu;&iota;ώ&nu;&epsilon;&tau;&alpha;&iota;
        &mu;&epsilon; &tau;&eta; &delta;&iota;&alpha;&phi;&omicron;&rho;ά
        &tau;&omicron;&upsilon; &pi;&omicron;&sigma;&omicron;ύ
        &pi;&omicron;&upsilon; &epsilon;ί&chi;&epsilon; &tau;&omicron;
        &pi;&rho;&omicron;ϊό&nu; &tau;&omicron; &omicron;&pi;&omicron;ί&omicron;
        &alpha;&phi;&alpha;&iota;&rho;έ&theta;&eta;&kappa;&epsilon;.
        &Epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;, &omicron;
        &chi;&rho;ή&sigma;&tau;&eta;&sigmaf;
        &alpha;&pi;&omicron;&delta;έ&chi;&epsilon;&tau;&alpha;&iota;
        &tau;&eta;&nu; &pi;&lambda;&eta;&rho;&omega;&mu;ή &tau;&eta;&sigmaf;
        &alpha;&xi;ί&alpha;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &pi;έ&rho;&alpha;&nu; &tau;&eta;&sigmaf; &alpha;&xi;ί&alpha;&sigmaf;
        &tau;&omicron;&upsilon;
        &sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&omicron;&upsilon;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omicron;&sigmaf; &kappa;&alpha;&iota;
        &tau;&omicron; &kappa;ό&sigma;&tau;&omicron;&sigmaf; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&eta;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
        &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά&sigmaf; &tau;&omega;&nu;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &tau;&eta;&sigmaf;
        &epsilon;&pi;&iota;&chi;&epsilon;ί&rho;&eta;&sigma;&eta;&sigmaf;
        &sigma;&tau;&omicron;&nu; &tau;&epsilon;&lambda;&iota;&kappa;ό
        &chi;&rho;ή&sigma;&tau;&eta;, &tau;&omicron;
        &omicron;&pi;&omicron;ί&omicron; &epsilon;&phi; &epsilon;&xi;ή&sigmaf;
        &omicron;&rho;ί&zeta;&epsilon;&tau;&alpha;&iota; &omega;&sigmaf;
        &ldquo;delivery fee&rdquo;, ό&pi;&omega;&sigmaf; &alpha;&upsilon;&tau;ό
        &alpha;&nu;&alpha;&gamma;&rho;ά&phi;&epsilon;&tau;&alpha;&iota;
        &sigma;&tau;&eta;&nu;
        &epsilon;&kappa;ά&sigma;&tau;&omicron;&tau;&epsilon;
        &phi;ό&rho;&mu;&alpha;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;.
      </p>
      <p>
        &Eta; &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;ή &theta;&alpha;
        &gamma;ί&nu;&epsilon;&tau;&alpha;&iota; &mu;έ&sigma;&omega;
        &tau;&omicron;&upsilon;
        &tau;&rho;&alpha;&pi;&epsilon;&zeta;&iota;&kappa;&omicron;ύ
        &iota;&delta;&rho;ύ&mu;&alpha;&tau;&omicron;&sigmaf; &mu;&epsilon;
        &tau;&eta;&nu; &epsilon;&pi;&omega;&nu;&upsilon;&mu;ί&alpha;
        &ldquo;&Tau;&rho;ά&pi;&epsilon;&zeta;&alpha;
        &Pi;&epsilon;&iota;&rho;&alpha;&iota;ώ&sigmaf; AE&rdquo;,
        &nbsp;&mu;&epsilon; &pi;ί&sigma;&tau;&omega;&sigma;&eta;
        &sigma;&tau;&eta;&nu; &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή
        &kappa;ά&rho;&tau;&alpha; &tau;&omicron;&upsilon;
        &chi;&rho;ή&sigma;&tau;&eta; &alpha;&pi;ό &tau;&eta;&nu;
        &omicron;&pi;&omicron;ί&alpha; έ&gamma;&iota;&nu;&epsilon; &eta;
        &chi;&rho;έ&omega;&sigma;&eta;, &epsilon;&nu;&tau;ό&sigmaf;
        &pi;έ&nu;&tau;&epsilon;
        &epsilon;&rho;&gamma;ά&sigma;&iota;&mu;&omega;&nu; &alpha;&pi;ό
        &tau;&eta;&nu; &eta;&mu;&epsilon;&rho;&omicron;&mu;&eta;&nu;ί&alpha;
        &chi;&rho;έ&omega;&sigma;&eta;&sigmaf;, &kappa;&alpha;&iota;
        &alpha;&phi;&omicron;ύ
        &pi;&rho;&omicron;&eta;&gamma;&omicron;&upsilon;&mu;έ&nu;&omega;&sigmaf;
        &delta;&omicron;&theta;&epsilon;ί &eta;
        &alpha;&pi;&alpha;&rho;&alpha;ί&tau;&eta;&tau;&eta;
        &epsilon;&nu;&tau;&omicron;&lambda;ή &alpha;&pi;ό &tau;&eta;&nu;
        &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha; &ldquo;stoferno
        IKE&rdquo; &eta; &omicron;&pi;&omicron;ί&alpha; &kappa;&alpha;&iota;
        &phi;έ&rho;&epsilon;&iota; &tau;&eta;&nu;
        &mu;&omicron;&nu;&alpha;&delta;&iota;&kappa;ή
        &epsilon;&upsilon;&theta;ύ&nu;&eta; &gamma;&iota;&alpha; &tau;&eta;&nu;
        &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;ή &tau;&omega;&nu;
        &chi;&rho;&eta;&mu;ά&tau;&omega;&nu; &sigma;ύ&mu;&phi;&omega;&nu;&alpha;
        &mu;&epsilon; &tau;&eta;&nu;
        &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή &tau;&eta;&sigmaf;
        &gamma;&iota;&alpha; &tau;&eta;&nu;
        &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;ή
        &chi;&rho;&eta;&mu;ά&tau;&omega;&nu; &alpha;&pi;ό
        &sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
        &pi;&epsilon;&lambda;&alpha;&tau;ώ&nu; &tau;&eta;&sigmaf;.
      </p>
      <p>
        &Sigma;&upsilon;&gamma;&kappa;&epsilon;&kappa;&rho;&iota;&mu;έ&nu;&alpha;,
        &eta; &mu;&eta;
        &epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &sigma;&upsilon;&nu;ί&sigma;&tau;&alpha;&tau;&alpha;&iota;
        &sigma;&tau;&eta; &mu;&eta;
        &pi;&alpha;&rho;ά&delta;&omicron;&sigma;&eta; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &alpha;&pi;ό &tau;&omicron;
        &kappa;&alpha;&tau;ά&sigma;&tau;&eta;&mu;&alpha; &alpha;&pi;ό
        &delta;&iota;&alpha;&pi;ί&sigma;&tau;&omega;&sigma;&eta;
        έ&lambda;&lambda;&epsilon;&iota;&psi;&eta;&sigmaf;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omicron;&sigmaf; ή
        &alpha;&delta;&upsilon;&nu;&alpha;&mu;ί&alpha; &tau;&omicron;&upsilon;
        &kappa;&alpha;&tau;&alpha;&sigma;&tau;ή&mu;&alpha;&tau;&omicron;&sigmaf;
        &nu;&alpha;
        &epsilon;&xi;&upsilon;&pi;&eta;&rho;&epsilon;&tau;ή&sigma;&epsilon;&iota;
        &tau;&eta;&nu;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
        &lambda;ό&gamma;&omega;
        &epsilon;&sigma;&phi;&alpha;&lambda;&mu;έ&nu;&eta;&sigmaf;
        &sigma;&upsilon;&nu;&epsilon;&nu;&nu;ό&eta;&sigma;&eta;&sigmaf;
        &kappa;&alpha;&iota; &epsilon;&nu;ώ
        &pi;&rho;&omicron;&eta;&gamma;&omicron;&upsilon;&mu;έ&nu;&omega;&sigmaf;
        &tau;&omicron; &kappa;&alpha;&tau;ά&sigma;&tau;&eta;&mu;&alpha;
        &epsilon;ί&chi;&epsilon; &delta;&eta;&lambda;ώ&sigma;&epsilon;&iota;
        &delta;&iota;&alpha;&theta;&epsilon;&sigma;&iota;&mu;ό&tau;&eta;&tau;&alpha;
        &tau;&omega;&nu; &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu;
        &kappa;&alpha;&iota; &tau;&eta;&nu;
        &delta;&upsilon;&nu;&alpha;&tau;ό&tau;&eta;&tau;&alpha;
        &epsilon;&kappa;&tau;έ&lambda;&epsilon;&sigma;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &mu;&epsilon;
        &alpha;&pi;&omicron;&tau;έ&lambda;&epsilon;&sigma;&mu;&alpha;
        &tau;&eta;&nu; &alpha;&pi;&omicron;&delta;&omicron;&chi;ή
        &tau;&eta;&sigmaf; &kappa;&alpha;&iota; &tau;&eta;&nu;
        &chi;&rho;έ&omega;&sigma;&eta; &tau;&eta;&sigmaf;
        &pi;&iota;&sigma;&tau;&omega;&tau;&iota;&kappa;ή&sigmaf; ή
        &chi;&rho;&epsilon;&omega;&sigma;&tau;&iota;&kappa;ή&sigmaf;
        &kappa;ά&rho;&tau;&alpha;&sigmaf; &tau;&omicron;&upsilon;
        &pi;&epsilon;&lambda;ά&tau;&eta; &kappa;&alpha;&iota;
        &pi;&alpha;&rho;ά&lambda;&lambda;&eta;&lambda;&eta;
        ά&rho;&nu;&eta;&sigma;&eta; &tau;&omicron;&upsilon;
        &pi;&epsilon;&lambda;ά&tau;&eta; &nu;&alpha;
        &epsilon;&xi;&upsilon;&pi;&eta;&rho;&epsilon;&tau;&eta;&theta;&epsilon;ί
        &sigma;&epsilon;
        &mu;&epsilon;&tau;&alpha;&gamma;&epsilon;&nu;έ&sigma;&tau;&epsilon;&rho;&omicron;
        &chi;&rho;ό&nu;&omicron; (ό&tau;&alpha;&nu; &delta;&eta;&lambda;.
        &tau;&omicron; &epsilon;&mu;&pi;ό&rho;&epsilon;&upsilon;&mu;&alpha;
        &theta;&alpha; &epsilon;ί&nu;&alpha;&iota;
        &delta;&iota;&alpha;&theta;έ&sigma;&iota;&mu;&omicron;),
        &kappa;&alpha;&iota; &sigma;&upsilon;&nu;&epsilon;&pi;ώ&sigmaf;
        &sigma;&tau;&eta;&nu; &alpha;&delta;&upsilon;&nu;&alpha;&mu;ί&alpha;
        &pi;&alpha;&rho;ά&delta;&omicron;&sigma;&eta;&sigmaf;
        &nbsp;&tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &Sigma;&epsilon; &kappa;&alpha;&mu;ί&alpha;
        &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &delta;&epsilon;&nu;
        &sigma;&upsilon;&nu;&iota;&sigma;&tau;ά &mu;&eta;
        &epsilon;&xi;&upsilon;&pi;&eta;&rho;έ&tau;&eta;&sigma;&eta;
        &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;
        &eta; &mu;&eta;
        &iota;&kappa;&alpha;&nu;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
        &tau;&omicron;&upsilon; &pi;&epsilon;&lambda;ά&tau;&eta;
        &gamma;&iota;&alpha; &tau;&eta;&nu;
        &pi;&omicron;&iota;ό&tau;&eta;&tau;&alpha; &tau;&omega;&nu;
        &pi;&rho;&omicron;ϊό&nu;&tau;&omega;&nu; &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;&sigmaf;,
        &gamma;&iota;&alpha; &tau;&eta;&nu; &omicron;&pi;&omicron;ί&alpha;
        &alpha;&pi;&omicron;&kappa;&lambda;&epsilon;&iota;&sigma;&tau;&iota;&kappa;ή
        &epsilon;&upsilon;&theta;ύ&nu;&eta; &phi;έ&rho;&epsilon;&iota;
        &tau;&omicron; &kappa;&alpha;&tau;ά&sigma;&tau;&eta;&mu;&alpha;,
        &kappa;&alpha;&iota; &sigma;&upsilon;&nu;&epsilon;&pi;ώ&sigmaf;
        &omicron;&upsilon;&delta;&epsilon;&mu;ί&alpha;
        &upsilon;&pi;&omicron;&chi;&rho;έ&omega;&sigma;&eta;
        &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;ή&sigmaf;
        &tau;&omicron;&upsilon; &kappa;ό&sigma;&tau;&omicron;&upsilon;&sigmaf;
        &tau;&eta;&sigmaf;
        &pi;&alpha;&rho;&epsilon;&chi;ό&mu;&epsilon;&nu;&eta;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf;
        &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά&sigmaf;,
        &delta;&epsilon;&nu; &gamma;&epsilon;&nu;&nu;ά&tau;&alpha;&iota;
        &sigma;&epsilon; &alpha;&upsilon;&tau;ή &tau;&eta;&nu;
        &pi;&epsilon;&rho;ί&pi;&tau;&omega;&sigma;&eta; &gamma;&iota;&alpha;
        &tau;&eta;&nu; &epsilon;&tau;&alpha;&iota;&rho;&epsilon;ί&alpha;.
      </p>
      <p>
        <strong
          >3.2
          &Sigma;&upsilon;&nu;&alpha;&lambda;&lambda;&alpha;&gamma;έ&sigmaf;
          Courier Standard &amp; Courier Pro</strong
        >
      </p>
      <p>
        &Eta; &pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
        &epsilon;&pi;&iota;&sigma;&tau;&rho;&omicron;&phi;ή&sigmaf;
        &chi;&rho;&eta;&mu;ά&tau;&omega;&nu;
        &alpha;&nu;&alpha;&phi;έ&rho;&epsilon;&tau;&alpha;&iota;
        &alpha;&nu;&alpha;&lambda;&upsilon;&tau;&iota;&kappa;ό&tau;&epsilon;&rho;&alpha;
        &sigma;&tau;&omicron;&upsilon;&sigmaf;
        &gamma;&epsilon;&nu;&iota;&kappa;&omicron;ύ&sigmaf;
        ό&rho;&omicron;&upsilon;&sigmaf;
        &alpha;&nu;ά&lambda;&eta;&psi;&eta;&sigmaf;
        &mu;&epsilon;&tau;&alpha;&phi;&omicron;&rho;ά&sigmaf;
        &kappa;&alpha;&iota; &sigma;&tau;&omicron;&upsilon;&sigmaf;
        ό&rho;&omicron;&upsilon;&sigmaf; &chi;&rho;ή&sigma;&eta;&sigmaf;
        &upsilon;&pi;&eta;&rho;&epsilon;&sigma;ί&alpha;&sigmaf; Stoferno
        Business Courier.&nbsp;
      </p>
      <p>
        <strong
          >4. &Pi;&omicron;&lambda;&iota;&tau;&iota;&kappa;ή
          &Pi;&lambda;&eta;&rho;&omega;&mu;ώ&nu; &tau;&eta;&sigmaf;
          &Epsilon;&Epsilon;</strong
        >
      </p>
      <p>
        &Alpha;&pi;ό &tau;&omicron; &Sigma;ά&beta;&beta;&alpha;&tau;&omicron; 14
        &Sigma;&epsilon;&pi;&tau;&epsilon;&mu;&beta;&rho;ί&omicron;&upsilon;
        2019 ό&lambda;&epsilon;&sigmaf; &omicron;&iota;
        &eta;&lambda;&epsilon;&kappa;&tau;&rho;&omicron;&nu;&iota;&kappa;έ&sigmaf;
        &pi;&lambda;&eta;&rho;&omega;&mu;έ&sigmaf; (ό&chi;&iota;
        &mu;ό&nu;&omicron; &sigma;&tau;&eta; Stoferno.gr)
        &alpha;&pi;&alpha;&iota;&tau;&omicron;ύ&nu;
        &ldquo;&Iota;&sigma;&chi;&upsilon;&rho;ή
        &tau;&alpha;&upsilon;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
        &pi;&epsilon;&lambda;ά&tau;&eta;&rdquo; &lambda;ό&gamma;&omega;
        έ&nu;&alpha;&rho;&xi;&eta;&sigmaf; &iota;&sigma;&chi;ύ&omicron;&sigmaf;
        &nu;έ&alpha;&sigmaf;
        &Epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;ή&sigmaf;
        &omicron;&delta;&eta;&gamma;ί&alpha;&sigmaf; &sigma;&epsilon;
        ό&lambda;&eta; &tau;&eta;&nu; &Epsilon;&Epsilon;.&nbsp;
      </p>
      <p>
        <strong>4.1 </strong>&Tau;&iota;
        &sigma;&eta;&mu;&alpha;ί&nu;&epsilon;&iota; &alpha;&upsilon;&tau;ό
        &gamma;&iota;&alpha; &tau;&iota;&sigmaf;
        &pi;&lambda;&eta;&rho;&omega;&mu;έ&sigmaf; &sigma;&omicron;&upsilon;
        &sigma;&tau;&eta; Stoferno.gr: Ό&tau;&alpha;&nu;
        &beta;ά&zeta;&epsilon;&iota;&sigmaf; &mu;&iota;&alpha;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;
        &sigma;&tau;&eta; Stoferno.gr, ί&sigma;&omega;&sigmaf;
        &chi;&rho;&epsilon;&iota;&alpha;&sigma;&tau;&epsilon;ί &nu;&alpha;
        &alpha;&kappa;&omicron;&lambda;&omicron;&upsilon;&theta;ή&sigma;&epsilon;&iota;&sigmaf;
        &epsilon;&pi;&iota;&pi;&lambda;έ&omicron;&nu;
        &beta;ή&mu;&alpha;&tau;&alpha; &gamma;&iota;&alpha; &nu;&alpha;
        &epsilon;&pi;&iota;&beta;&epsilon;&beta;&alpha;&iota;ώ&sigma;&epsilon;&iota;&sigmaf;
        &tau;&eta;&nu; &tau;&alpha;&upsilon;&tau;ό&tau;&eta;&tau;&alpha;
        &sigma;&omicron;&upsilon;:
      </p>
      <p>
        4.1.1 &Tau;&alpha; &beta;ή&mu;&alpha;&tau;&alpha;
        &epsilon;&xi;&alpha;&rho;&tau;ώ&nu;&tau;&alpha;&iota; &alpha;&pi;ό
        &tau;&eta;&nu; &tau;&rho;ά&pi;&epsilon;&zeta;&alpha;
        &sigma;&omicron;&upsilon; &kappa;&alpha;&iota;
        &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota; &nu;&alpha;
        &pi;&epsilon;&rho;&iota;&lambda;&alpha;&mu;&beta;ά&nu;&omicron;&upsilon;&nu;
        &tau;&eta;&nu; &epsilon;&iota;&sigma;&alpha;&gamma;&omega;&gamma;ή
        &sigma;&tau;&eta;&nu; &epsilon;&phi;&alpha;&rho;&mu;&omicron;&gamma;ή
        &tau;&eta;&sigmaf; &tau;&rho;ά&pi;&epsilon;&zeta;&alpha;&sigmaf;,
        &delta;&alpha;&kappa;&tau;&upsilon;&lambda;&iota;&kappa;ό
        &alpha;&pi;&omicron;&tau;ύ&pi;&omega;&mu;&alpha;,
        &kappa;&tau;&lambda;.&nbsp;
      </p>
      <p>
        4.1.2 &Pi;&rho;&alpha;&kappa;&tau;&iota;&kappa;ά,
        ό&lambda;&epsilon;&sigmaf; &omicron;&iota;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&epsilon;&sigmaf;
        ά&nu;&omega; &tau;&omega;&nu; 30&euro; &kappa;&alpha;&iota;
        &kappa;ά&theta;&epsilon; 6&eta;
        &pi;&alpha;&rho;&alpha;&gamma;&gamma;&epsilon;&lambda;ί&alpha;,
        &theta;&alpha; &alpha;&pi;&alpha;&iota;&tau;&omicron;ύ&nu;
        &iota;&sigma;&chi;&upsilon;&rho;ή
        &tau;&alpha;&upsilon;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;.&nbsp;
      </p>
      <p>
        4.1.3 &Eta;
        &delta;&iota;&alpha;&delta;&iota;&kappa;&alpha;&sigma;ί&alpha;
        &pi;&lambda;&eta;&rho;&omega;&mu;ή&sigmaf; &theta;&alpha;
        &gamma;ί&nu;&epsilon;&iota; &pi;&iota;&omicron; &alpha;&rho;&gamma;ή
        &kappa;&alpha;&iota;
        &pi;&epsilon;&rho;ί&pi;&lambda;&omicron;&kappa;&eta;. &Eta;
        &nu;&omicron;&mu;&omicron;&theta;&epsilon;&sigma;ί&alpha;
        &tau;&eta;&sigmaf; &Epsilon;&Epsilon; &epsilon;ί&nu;&alpha;&iota;
        &kappa;ά&tau;&iota; &pi;&omicron;&upsilon; &delta;&epsilon;&nu;
        &mu;&pi;&omicron;&rho;&omicron;ύ&mu;&epsilon; &nu;&alpha;
        &epsilon;&pi;&eta;&rho;&epsilon;ά&sigma;&omicron;&upsilon;&mu;&epsilon;,
        &omega;&sigma;&tau;ό&sigma;&omicron; &zeta;&eta;&tau;ά&mu;&epsilon;
        &sigma;&upsilon;&gamma;&gamma;&nu;ώ&mu;&eta; &gamma;&iota;&alpha;
        &tau;&eta;&nu;
        &alpha;&nu;&alpha;&sigma;&tau;ά&tau;&omega;&sigma;&eta;.&nbsp;
      </p>
      <p>
        <strong
          >4.2 &Tau;&iota;
          &pi;&rho;&omicron;&tau;&epsilon;ί&nu;&omicron;&upsilon;&mu;&epsilon;
          &nu;&alpha; &kappa;ά&nu;&epsilon;&iota;&sigmaf;:
        </strong>
      </p>
      <p>
        4.2.1 Έ&lambda;&epsilon;&gamma;&xi;&epsilon; &pi;&omega;&sigmaf;
        &epsilon;&phi;&alpha;&rho;&mu;ό&zeta;&epsilon;&iota; &tau;&eta;&nu;
        &epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;ή
        &omicron;&delta;&eta;&gamma;ί&alpha; &eta;
        &tau;&rho;ά&pi;&epsilon;&zeta;&alpha; &sigma;&omicron;&upsilon;
        (&gamma;&iota;&alpha;
        &pi;&alpha;&rho;ά&delta;&epsilon;&iota;&gamma;&mu;&alpha;
        &mu;&pi;&omicron;&rho;&epsilon;ί&sigmaf; &nu;&alpha;
        &psi;ά&xi;&epsilon;&iota;&sigmaf; &sigma;&tau;&omicron; Google
        &lsquo;&rsquo;PSD [&tau;&omicron; ό&nu;&omicron;&mu;&alpha;
        &tau;&eta;&sigmaf; &tau;&rho;ά&pi;&epsilon;&zeta;ά&sigmaf;
        &sigma;&omicron;&upsilon;]&rdquo;)
      </p>
      <p>
        4.2.2
        &Epsilon;&gamma;&kappa;&alpha;&tau;ά&sigma;&tau;&eta;&sigma;&epsilon;
        &pi;&iota;&theta;&alpha;&nu;έ&sigmaf;
        &epsilon;&phi;&alpha;&rho;&mu;&omicron;&gamma;έ&sigmaf;
        &kappa;&alpha;&iota; &mu;έ&tau;&rho;&alpha;
        &alpha;&sigma;&phi;&alpha;&lambda;&epsilon;ί&alpha;&sigmaf;
        &pi;&omicron;&upsilon; &alpha;&pi;&alpha;&iota;&tau;&epsilon;ί &eta;
        &tau;&rho;ά&pi;&epsilon;&zeta;ά &sigma;&omicron;&upsilon;.&nbsp;
      </p>
      <p>
        4.2.3 &Beta;&epsilon;&beta;&alpha;&iota;ώ&sigma;&omicron;&upsilon;
        ό&tau;&iota; &eta; &epsilon;&phi;&alpha;&rho;&mu;&omicron;&gamma;ή
        &tau;&eta;&sigmaf; Stoferno.gr &epsilon;ί&nu;&alpha;&iota;
        &alpha;&nu;&alpha;&beta;&alpha;&theta;&mu;&iota;&sigma;&mu;έ&nu;&eta;
        &sigma;&tau;&eta;&nu;
        &tau;&epsilon;&lambda;&epsilon;&upsilon;&tau;&alpha;ί&alpha;
        έ&kappa;&delta;&omicron;&sigma;&eta;, &kappa;&alpha;&theta;ώ&sigmaf;
        &pi;&alpha;&lambda;&alpha;&iota;ό&tau;&epsilon;&rho;&epsilon;&sigmaf;
        &epsilon;&kappa;&delta;ό&sigma;&epsilon;&iota;&sigmaf;
        &epsilon;&nu;&delta;έ&chi;&epsilon;&tau;&alpha;&iota; &nu;&alpha;
        &pi;&alpha;&rho;&omicron;&upsilon;&sigma;&iota;ά&zeta;&omicron;&upsilon;&nu;
        &pi;&rho;ό&beta;&lambda;&eta;&mu;&alpha; &mu;&epsilon;
        &tau;&iota;&sigmaf; &nu;έ&epsilon;&sigmaf;
        &delta;&iota;&alpha;&delta;&iota;&kappa;&alpha;&sigma;ί&epsilon;&sigmaf;
        &pi;&lambda;&eta;&rho;&omega;&mu;ή&sigmaf;.
      </p>
      <p>
        &Gamma;&iota;&alpha;
        &pi;&epsilon;&rho;&iota;&sigma;&sigma;ό&tau;&epsilon;&rho;&epsilon;&sigmaf;
        &pi;&lambda;&eta;&rho;&omicron;&phi;&omicron;&rho;ί&epsilon;&sigmaf;
        &sigma;&chi;&epsilon;&tau;&iota;&kappa;ά &mu;&epsilon; &tau;&omicron;
        PSD2 &Iota;&sigma;&chi;&upsilon;&rho;ή
        &Tau;&alpha;&upsilon;&tau;&omicron;&pi;&omicron;ί&eta;&sigma;&eta;
        &tau;&omicron;&upsilon; &Pi;&epsilon;&lambda;ά&tau;&eta; [PSD2 Strong
        Customer Authentication (SCA) directive],
        &epsilon;&pi;&iota;&sigma;&kappa;έ&psi;&omicron;&upsilon;
        &tau;&omicron;&nu; &iota;&sigma;&tau;ό&tau;&omicron;&pi;&omicron;
        &tau;&eta;&sigmaf;
        &Epsilon;&upsilon;&rho;&omega;&pi;&alpha;ϊ&kappa;ή&sigmaf;
        &Kappa;&epsilon;&nu;&tau;&rho;&iota;&kappa;ή&sigmaf;
        &Tau;&rho;ά&pi;&epsilon;&zeta;&alpha;&sigmaf; &epsilon;&delta;ώ:
      </p>
      <p>
        https://www.ecb.europa.eu/paym/intro/mip-online/2018/html/1803_revisedpsd.en.html
      </p>
      <p><br /></p>
    </v-container>
  </div>
</template>

<script>
import LegalHeader from "./LegalHeader.vue";
export default {
  components: { LegalHeader },
  name: "PaymentsPolicy",
  data() {
    return {};
  },
};
</script>

<style></style>
